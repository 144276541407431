import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AesRandomizerService } from './randomizer_service/aes-randomizer.service';

const routes: Routes = [
  {
    path: '',
    redirectTo:
      localStorage.getItem(AesRandomizerService.pnkyStr) &&
      Capacitor.getPlatform() === 'android'
        ? 'pinlogin'
        : 'mod0101',
    pathMatch: 'full',
  },
  {
    path: 'mod0101',
    loadChildren: () =>
      import('./mod0101/mod0101.module').then((m) => m.Mod0101PageModule),
  },
  {
    path: 'applynow',
    loadChildren: () =>
      import('./admission/mod0202/mod0202.module').then(
        (m) => m.Mod0202PageModule
      ),
  },

  {
    path: 'checkstatus',
    loadChildren: () =>
      import('./admission/mod02014/mod02014.module').then(
        (m) => m.Mod02014PageModule
      ),
  },

  {
    path: 'master',
    loadChildren: () =>
      import('./mastermodule/mastermodule.module').then(
        (m) => m.MastermodulePageModule
      ),
  },
  {
    path: 'mod0102',
    loadChildren: () =>
      import('./mod0102/mod0102.module').then((m) => m.Mod0102PageModule),
  },
  {
    path: 'mod0103',
    loadChildren: () =>
      import('./mod0103/mod0103.module').then((m) => m.Mod0103PageModule),
  },
  {
    path: 'mod0106',
    loadChildren: () =>
      import('./mod0106/mod0106.module').then((m) => m.Mod0106PageModule),
  },
  {
    path: 'admission',
    loadChildren: () =>
      import('./admission/admission.module').then((m) => m.AdmissionPageModule),
  },
  {
    path: 'mod0115',
    loadChildren: () =>
      import('./mod0115/mod0115.module').then((m) => m.Mod0115PageModule),
  },
  {
    path: 'mod0116',
    loadChildren: () =>
      import('./mod0116/mod0116.module').then((m) => m.Mod0116PageModule),
  },
  {
    path: 'exam',
    loadChildren: () =>
      import('./exam/exam.module').then((m) => m.ExamPageModule),
  },
  {
    path: 'mod0305',
    loadChildren: () =>
      import('./mod0305/mod0305.module').then((m) => m.Mod0305PageModule),
  },
  {
    path: 'mod0123',
    loadChildren: () =>
      import('./mod0123/mod0123.module').then((m) => m.Mod0123PageModule),
  },
  {
    path: 'mod0125',
    loadChildren: () =>
      import('./mod0125/mod0125.module').then((m) => m.Mod0125PageModule),
  },
  {
    path: 'mod0127',
    loadChildren: () =>
      import('./mod0127/mod0127.module').then((m) => m.Mod0127PageModule),
  },
  {
    path: 'mod0130',
    loadChildren: () =>
      import('./mod0130/mod0130.module').then((m) => m.Mod0130PageModule),
  },
  {
    path: 'mod0131',
    loadChildren: () =>
      import('./mod0131/mod0131.module').then((m) => m.Mod0131PageModule),
  },
  {
    path: 'mod0132',
    loadChildren: () =>
      import('./mod0132/mod0132.module').then((m) => m.Mod0132PageModule),
  },
  {
    path: 'mod0133',
    loadChildren: () =>
      import('./mod0133/mod0133.module').then((m) => m.Mod0133PageModule),
  },
  {
    path: 'mod0135',
    loadChildren: () =>
      import('./mod0135/mod0135.module').then((m) => m.Mod0135PageModule),
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('./accounts/accounts.module').then((m) => m.AccountsPageModule),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsPageModule),
  },
  {
    path: 'mod0144',
    loadChildren: () =>
      import('./mod0144/mod0144.module').then((m) => m.Mod0144PageModule),
  },
  {
    path: 'mod0146',
    loadChildren: () =>
      import('./mod0146/mod0146.module').then((m) => m.Mod0146PageModule),
  },
  {
    path: 'mod0166',
    loadChildren: () =>
      import('./mod0166/mod0166.module').then((m) => m.Mod0166PageModule),
  },
  {
    path: 'mod0175',
    loadChildren: () =>
      import('./mod0175/mod0175.module').then((m) => m.Mod0175PageModule),
  },
  {
    path: 'scanner',
    loadChildren: () =>
      import('./scanner/scanner.module').then((m) => m.ScannerPageModule),
  },
  {
    path: 'pinlogin',
    loadChildren: () =>
      import('./pinlogin/pinlogin.module').then((m) => m.PinloginPageModule),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./billing/billing.module').then((m) => m.BillingPageModule),
  },
  {
    path: 'mod0174',
    loadChildren: () =>
      import('./mod0174/mod0174.module').then((m) => m.Mod0174PageModule),
  },
  {
    path: 'mod0176',
    loadChildren: () =>
      import('./mod0176/mod0176.module').then((m) => m.Mod0176PageModule),
  },
  {
    path: 'mod0177',
    loadChildren: () =>
      import('./mod0177/mod0177.module').then((m) => m.Mod0177PageModule),
  },
  {
    path: 'mod0184',
    loadChildren: () =>
      import('./mod0184/mod0184.module').then((m) => m.Mod0184PageModule),
  },

  {
    path: 'mod0119',
    loadChildren: () =>
      import('./mod0119/mod0119.module').then((m) => m.Mod0119PageModule),
  },
  {
    path: 'mod0185',
    loadChildren: () =>
      import('./mod0185/mod0185.module').then((m) => m.Mod0185PageModule),
  },
  {
    path: 'mod02018',
    loadChildren: () =>
      import('./mod02018/mod02018.module').then((m) => m.Mod02018PageModule),
  },
  {
    path: 'mod02019',
    loadChildren: () =>
      import('./mod02019/mod02019.module').then((m) => m.Mod02019PageModule),
  },
  {
    path: 'mod0411',
    loadChildren: () =>
      import('./mod0411/mod0411.module').then((m) => m.Mod0411PageModule),
  },
  {
    path: 'mod0412',
    loadChildren: () =>
      import('./mod0412/mod0412.module').then((m) => m.Mod0412PageModule),
  },
  {
    path: 'mod0416',
    loadChildren: () =>
      import('./mod0416/mod0416.module').then((m) => m.Mod0416PageModule),
  },
  {
    path: 'mod0255',
    loadChildren: () =>
      import('./mod0255/mod0255.module').then((m) => m.Mod0255PageModule),
  },

  {
    path: 'mod0260',
    loadChildren: () => import('./mod0260/mod0260.module').then( m => m.Mod0260PageModule)
  },
  {
    path: 'mod0261',
    loadChildren: () => import('./mod0261/mod0261.module').then( m => m.Mod0261PageModule)
  },
 


 

  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
