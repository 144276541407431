import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Storage } from '@ionic/storage';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Http, HttpOptions } from '@capacitor-community/http';
import { from, Observable, Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import * as QRCode from 'qrcode';
import { ToastController } from '@ionic/angular';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AesRandomizerService } from 'src/app/randomizer_service/aes-randomizer.service';
import { GetDataType, PostDataType } from './service.types';
import { finalize } from 'rxjs/operators';
import * as JsBarcode from 'jsbarcode';

type CptSessionKeyType = {
  [key: string]: string;
};
type CptViewKeyType = {
  [key: string]: {
    'session-for': string;
    'cptid-keyname': string;
  };
};
type GenericJSONobj = {
  [key: string]: any;
};

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  session_key = '';

  app_pin_key: string = 'PSTpin';
  admin_usertype: string = '1';
  cpt_session_keys: CptSessionKeyType = {
    mod0101: '',
    mod0102: '',
    mod0123: '',
    mod0129: '',
    mod0132: '',
    mod0133: '',
    mod0184: '',
  };
  cpt_views: CptViewKeyType = {
    mod0129: {
      'session-for': 'captcha-course_reg-user',
      'cptid-keyname': 'creg-user-cptid',
    },
    mod0101: {
      'session-for': 'captcha-login',
      'cptid-keyname': 'cptid',
    },
    mod0102: {
      'session-for': 'captcha-register',
      'cptid-keyname': 'reg-cptid',
    },
    mod0123: {
      'session-for': 'captcha-mobno_validate',
      'cptid-keyname': 'mobno-cptid',
    },
    mod0132: {
      'session-for': 'captcha-course_reg_old',
      'cptid-keyname': 'creg_old-cptid',
    },
    mod0133: {
      'session-for': 'captcha-course_reg-open',
      'cptid-keyname': 'creg-cptid',
    },
    mod0184: {
      'session-for': 'captcha-user-kyc',
      'cptid-keyname': 'kyc-user-cptid',
    },
    mod02014: {
      'session-for': 'captcha-application-status',
      'cptid-keyname': 'applic-status-cptid',
    },
    mod0202: {
      'session-for': 'captcha-application-form',
      'cptid-keyname': 'applic-form-cptid',
    },
  };

  lat: any;
  lon: any;

  result = {
    action: '1',
    response: {},
  };
  response = {};
  action = '1';

  isLoading = false;
  AppLoader: HTMLIonLoadingElement | null = null;

  show_spinner: boolean = false;

  private sharedData: any;

  saved_nav_stack = [];
  backButtonSubscription: Subscription | undefined;

  alert_exist: boolean = false;

  MAX_FILESIZE_COMMON: number = 400 * 1024; // 400 kilobytes

  DefaultModuleName: any;

  current_att_loc_id: any = '';

  privilage_array: string[] = [];

  constructor(
    private http: HttpClient,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private storage: Storage,
    public platform: Platform,
    private router: Router,
    private cookies: CookieService,
    private toastController: ToastController,
    private ardService: AesRandomizerService
  ) {
    console.log('test cpt id on service init :');
    for (const [key, value] of Object.entries(this.cpt_views)) {
      console.log(
        `
        view_name = ${key}; 
        key_name = ${value['cptid-keyname']}; 
        key_value = ${this.localStorage.getItem(value['cptid-keyname'])}
        `
      );
    }
  }

  platform_back_button_function(url: String) {
    var back_url: String = '/master';

    if (url == 'mod0129') {
      back_url = '/master';
    } else if (url == 'mod0119') {
      back_url = '/master';
    } else if (url.includes('mod0137')) {
      back_url = '/exam/mod0129';
    } else if (url.includes('mod0134')) {
      back_url = 'exam/mod0129';
    } else if (url == 'mod0103') {
      back_url = '/master';
    } else if (url == 'mod0312') {
      back_url = '/master';
    } else {
      back_url = '/master';
    }

    return back_url;
  }

  SplitUrl(url: String) {
    if (this.DefaultModuleName?.length) {
      return this.DefaultModuleName;
    } else {
      var parts = url.split('/');
      var lastPart = parts[parts.length - 1].split(';')[0];
      this.DefaultModuleName = lastPart;
      return lastPart;
    }
  }

  setSharedData(data: any) {
    this.sharedData = data;
  }

  getSharedData() {
    return this.sharedData;
  }

  set_website_colour(data) {
    this.localStorage.setItem('bg_color', data.bg_color);
    this.localStorage.setItem('menu_bg_color', data.menu_bg_color);
    this.localStorage.setItem('menu_internal_color', data.menu_internal_color);
    this.localStorage.setItem('nav_bg_color', data.nav_bg_color);
    this.localStorage.setItem('menu_txt_color', data.menu_txt_color);
    this.localStorage.setItem('nav_txt_color', data.nav_txt_color);
    this.localStorage.setItem('menu_hover_color', data.menu_hover_color);
    this.localStorage.setItem('btn_bg_color', data.btn_bg_color);
    this.localStorage.setItem('btn_txt_color', data.btn_txt_color);
    this.localStorage.setItem('btn_hover_color', data.btn_hover_color);
    this.localStorage.setItem('cancel_btn_bg_color', data.cancel_btn_bg_color);
    this.localStorage.setItem(
      'cancel_btn_txt_color',
      data.cancel_btn_txt_color
    );
    this.localStorage.setItem(
      'cancel_btn_hover_color',
      data.cancel_btn_hover_color
    );
    this.localStorage.setItem('tab_head_bg_color', data.tab_head_bg_color);
    this.localStorage.setItem('tab_head_txt_color', data.tab_head_txt_color);
  }

  get_website_colour() {
    var bg_color = this.localStorage.getItem('bg_color');
    var menu_bg_color = this.localStorage.getItem('menu_bg_color');
    var menu_internal_color = this.localStorage.getItem('menu_internal_color');
    var nav_bg_color = this.localStorage.getItem('nav_bg_color');
    var menu_txt_color = this.localStorage.getItem('menu_txt_color');
    var nav_txt_color = this.localStorage.getItem('nav_txt_color');
    var menu_hover_color = this.localStorage.getItem('menu_hover_color');
    var btn_bg_color = this.localStorage.getItem('btn_bg_color');
    var btn_txt_color = this.localStorage.getItem('btn_txt_color');
    var btn_hover_color = this.localStorage.getItem('btn_hover_color');
    var cancel_btn_bg_color = this.localStorage.getItem('cancel_btn_bg_color');
    var cancel_btn_txt_color = this.localStorage.getItem(
      'cancel_btn_txt_color'
    );
    var cancel_btn_hover_color = this.localStorage.getItem(
      'cancel_btn_hover_color'
    );
    var tab_head_bg_color = this.localStorage.getItem('tab_head_bg_color');
    var tab_head_txt_color = this.localStorage.getItem('tab_head_txt_color');

    this.setCSSVariable('--primary_body_color', bg_color);
    this.setCSSVariable('--nav_bar_primary_color', menu_bg_color);
    this.setCSSVariable('--nav_bar_secondary_color', menu_internal_color);
    this.setCSSVariable('--nav_bar_header_bg_color', nav_bg_color);
    this.setCSSVariable('--side_menu_text_color', menu_txt_color);
    this.setCSSVariable('--menu_bar_text_color', nav_txt_color);
    this.setCSSVariable('--nav_bar_hover_color', menu_hover_color);
    this.setCSSVariable('--solo_button_back_colour', btn_bg_color);
    this.setCSSVariable('--solo_button_text_colour', btn_txt_color);
    this.setCSSVariable('--solo_button_hover_colour', btn_hover_color);
    this.setCSSVariable('--cancel_button_back_colour', cancel_btn_bg_color);
    this.setCSSVariable('--cancel_button_text_colour', cancel_btn_txt_color);
    this.setCSSVariable('--cancel_button_hover_colour', cancel_btn_hover_color);
    this.setCSSVariable('--table_header_back_color', tab_head_bg_color);
    this.setCSSVariable('--table_header_text_color', tab_head_txt_color);
  }

  // jinoth Qr entry
  //position:'top' | 'middle' | 'bottom'
  async presenttoast(
    msg: any,
    position: 'top' | 'middle' | 'bottom' = 'middle'
  ) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1500,
      position: position,
    });

    await toast.present();
  }

  getLocation() {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition((pos) =>
        this.showPosition(pos)
      );
    }
  }

  showPosition(position: any) {
    this.lat = position.coords.latitude;
    this.lon = position.coords.longitude;
  }

  generateQRCode(data: string): Promise<string> {
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(data, (err: any, url: string) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  }

  IsValidMobileNumber(mobileNumber: string) {
    // Remove any non-digit characters
    const cleanedNumber = mobileNumber.replace(/\D/g, '');

    // Check if the cleaned number has exactly 10 digits and is not all zeros
    return /^\d{10}$/.test(cleanedNumber) && cleanedNumber !== '0000000000';
  }

  // call_api_post_ajax(
  //   url: string,
  //   data: { [key: string]: any },
  //   modname: string = '',
  //   callback: Function = () => {}
  // ): void {
  //   $.ajax({
  //     url: url,
  //     method: 'POST',
  //     headers: {
  //       Authorization: this.get_session_id_login(),
  //       'Content-Type': 'application/json',
  //       // modname: modname,
  //     },
  //     data: JSON.stringify({ ...data, modname }),
  //     success: function (response) {
  //       callback(response);
  //     },
  //     error: function (xhr, status, error) {
  //       console.log('Error:', error);
  //     },
  //   });
  // }

  call_api_post(
    url: string,
    data: { [key: string]: any } | FormData,
    modname: string = '',
    isFormData: boolean = false
  ): Observable<any> {
    if (isFormData) data.append('modname', modname);
    else data = { ...data, modname };
    const headers = {
      'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
      Authorization: this.get_session_id_login(),
    };
    return this.http.post(url, data, { headers });
  }

  appendParamsToGetUrl(
    url: string,
    data: { [key: string]: string | number | null }
  ): string {
    let urlobj = new URL(url);
    for (let key in data) {
      if (key == 'modname' && urlobj.searchParams.get(key)) {
        // skip changing modname
        continue;
      }
      if (data[key] != null) urlobj.searchParams.set(key, String(data[key]));
    }
    return urlobj.href;
  }

  defaultErrHandler(err: any, onErrModalHide = () => {}) {
    const {
      error: errData,
      headers,
      message,
      name,
      status,
      statusText,
      url,
    } = err;
    console.log(err);

    let errText = 'An error occurred';

    if (status == 403) {
      console.log(err, '403');
      errText = errData?.detail || 'Invalid Session Key';
      if (errText == 'Session Expired') {
        // TODO: debug for android
        if (Capacitor.getPlatform() == 'android') {
          // goto pinlogin page only for android app
          this.alert.warningAlert({
            text: 'Your Session Has Expired, Please Login',
            action: 'warning',
            callback: () => {
              onErrModalHide();
              window.location.replace('/pinlogin');
            },
          });
        } else {
          // alert and redirect to login after properly clearing localstorage data of user
          this.alert.warningAlert({
            text: 'Your Session Has Expired, Please Login',
            action: 'warning',
            callback: () => {
              onErrModalHide();
              this.logout();
            },
          });
        }
      } else {
        this.alert.warningAlert({
          text: errText,
          action: 'error',
          callback: () => {
            onErrModalHide();
            this.logout();
          },
        });
      }
    } else if (status == 0) {
      errText = 'Network Error';
      this.alert.warningAlert({
        text: errText,
        action: 'error',
        callback: () => {
          // TODO: hide loader if needed
          onErrModalHide();
          if (this.show_spinner) this.show_spinner = false;
        },
      });
    } else {
      this.common_errorCb(err, '', onErrModalHide);
    }
  }

  common_errorCb = (err: any, errTxt = '', onModalHide = () => {}) => {
    console.log(err);
    let { error = 1, message = 'An error occurred' } = err.error;
    if (error) {
      this.alert.warningAlert({
        text: errTxt || message,
        action: 'error',
        callback: () => {
          // TODO: hide loader if needed
          onModalHide();
          if (this.show_spinner) this.show_spinner = false;
        },
      });
    } else {
      this.alert.warningAlert({
        text: message || 'Network access working',
        // action: 'info',
        callback: () => {
          // TODO: hide loader if needed
          onModalHide();
          if (this.show_spinner) this.show_spinner = false;
        },
      });
    }
  };

  defaultCompleteHandler = () => {
    // console.log('http post completed');
  };

  call_http_get(getObj: GetDataType): Subscription {
    const {
      url,
      modname = '',
      successCb = (response) => {},
      errorCb,
      afterError,
      completeCb = this.defaultCompleteHandler,
    }: GetDataType = getObj;

    const headers = {
      Authorization: this.get_session_id_login(),
    };

    let errorHandlerCb = (error: any) => {};
    if (!errorCb && !afterError) {
      errorHandlerCb = this.defaultErrHandler;
    } else if (errorCb) {
      errorHandlerCb = errorCb;
    } else if (afterError) {
      errorHandlerCb = (error) => {
        this.defaultErrHandler(error, () => {
          afterError(error);
        });
      };
    }

    return this.http
      .get(this.appendParamsToGetUrl(url, { modname }), {
        headers,
      })
      .pipe(
        finalize(completeCb)
        // called on both sucess and error
      )
      .subscribe({ next: successCb, error: errorHandlerCb });
    // .subscribe({
    //   next: successCb, error: errorHandlerCb,
    //   complete: () => {
    //     // called after success/next only, not called after error
    //   },
    // })
  }

  call_http_post(
    postObj: PostDataType,
    control_spinner_locally: boolean = false
  ): Subscription {
    const {
      url,
      modname = '',
      isFormData = false,
      responseType,
      successCb = (response) => {},
      errorCb,
      afterError,
      completeCb = this.defaultCompleteHandler,
    }: PostDataType = postObj;

    let data: FormData | GenericJSONobj | undefined = postObj.data;
    if (!data) {
      if (isFormData) data = new FormData();
      else data = {};
    }

    if (isFormData) {
      if (!data.get('modname')) data.append('modname', modname);
    } else {
      // if (!data['modname'])
      if (!('modname' in data)) data = { ...data, modname };
    }

    const headers: GenericJSONobj = {
      Authorization: this.get_session_id_login(),
    };

    if (!isFormData) headers['Content-Type'] = 'application/json';

    const requestOptions: GenericJSONobj = {
      headers,
    };

    if (responseType) requestOptions['responseType'] = responseType;

    let errorHandlerCb = (error: any) => {};
    if (!errorCb && !afterError) {
      errorHandlerCb = (err) => {
        this.defaultErrHandler(err);
      };
    } else if (errorCb) {
      errorHandlerCb = errorCb;
    } else if (afterError) {
      errorHandlerCb = (error) => {
        this.defaultErrHandler(error, () => {
          afterError(error);
        });
      };
    }

    // // using Http.request doesnt get intercepted by HttpInterceptor
    // const options: HttpOptions = { url, method: 'POST', headers, data };
    // return from(Http.request(options));

    if (!control_spinner_locally) this.show_spinner = true;

    return this.http
      .post(url, data, requestOptions)
      .pipe(
        finalize(() => {
          if (!control_spinner_locally) this.show_spinner = false;
          completeCb();
        })
      )
      .subscribe({ next: successCb, error: errorHandlerCb });
  }

  private getLSItem = (key: string) => {
    return this.ardService.removeRandomizer(
      localStorage.getItem(this.ardService.applyRandomizer(key)) ||
        AesRandomizerService.emptyStr
    );
    // default value : null
  };

  private setLSItem = (key: string, value: string) => {
    if (value == null || value == undefined) value = '';
    localStorage.setItem(
      this.ardService.applyRandomizer(key),
      this.ardService.applyRandomizer(value)
    );
  };

  private removeLSItem = (key: string) => {
    localStorage.removeItem(this.ardService.applyRandomizer(key));
  };

  localStorage = {
    getItem: this.getLSItem,
    setItem: this.setLSItem,
    removeItem: this.removeLSItem,
  };

  get_session_id_login() {
    // if (this.platform.is('cordova')) return "";
    return this.localStorage.getItem('token');
  }

  get_user_details_in_cookies() {
    return this.localStorage.getItem('user_name');
  }

  // For alert
  async presentAlert(input_msg: any, callback: Function = () => {}) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      // header: 'Message',
      message: input_msg,
      buttons: ['OK'],
    });
    await alert.present();
    // const { role } = await alert.onDidDismiss()
    // console.log('onDidDismiss resolved with role', role);
    alert.onDidDismiss().then(() => callback());
  }

  call_api_post_JSON(url: any, data: any, callback: any) {
    $.ajax({
      url: url,
      method: 'POST',
      headers: {
        Authorization: this.get_session_id_login(),
      },
      data: JSON.stringify(data),
      success: function (response: any) {
        callback(response);
      },
      error: function (xhr: any, status: any, error: any) {
        console.log('Error:', error);
      },
    });
  }

  async confirmProceedToRegister() {
    if (this.alert_exist == false) {
      this.alert_exist = true;
      this.alert.confirmationAlert({
        title: 'User Not Found',
        text: 'Do You Want to Register ?',
        confirmCallback: () => {
          // this.show_spinner = false;
          this.alert_exist = false;
          this.router.navigate(['/mod0123']);
        },
        cancelCallback: () => {
          this.warningAlert({
            title: 'User Not Found',
            text: 'Try again',
            action: 'warning',
          });
          this.alert_exist = false;
        },
      });
    }
  }

  async presentConfirm(opts: any = {}) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: opts.header || '',
      message: opts.text || 'Kindly confirm this to proceed',
      buttons: [
        {
          text: opts.okBtnText || 'OK',
          id: 'confirm-button',
          handler: opts.okbtnHandler,
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: opts.cancelHandler,
        },
      ],
    });

    await alert.present();
  }

  presentModalLoader(
    props: {
      text?: string;
      showForMs?: number;
      onPresent?: () => void;
    } = {}
  ) {
    if (!this.isLoading) {
      console.log('loader called');
      let { text: message, showForMs: duration, onPresent = () => {} } = props;
      // console.log({ message, duration });
      this.isLoading = true;
      this.loadingController
        .create({
          message,
          duration,
          // animated: true, backdropDismiss: false,
        })
        .then((a) => {
          this.AppLoader = a;
          a.present().then(() => {
            console.log('loader presented');
            onPresent();
          });
        });
    }
  }

  dismissModalLoader(onDismiss: () => void = () => {}) {
    console.log('loader dismiss called');
    if (this.isLoading) {
      // if (!onDismiss) onDismiss = () => {};
      this.isLoading = false;
      if (this.AppLoader) {
        setTimeout(() => {
          if (this.AppLoader != null) {
            this.AppLoader.dismiss().then(() => {
              onDismiss();
              console.log('loader dismiss done');
              this.AppLoader = null;
            });
          }
        }, 500);
      }
    }
  }

  scroll_event(selector: string) {
    document?.querySelector(selector)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center', // 'true
      inline: 'center', // 'nearest'
    });
  }

  scroll_to_element(element: HTMLElement) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center', // 'true
      inline: 'center', // 'nearest'
    });
  }

  scroll_to_top() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  onContentScroll(event: any) {
    // Handle the scroll event here
    // You can access event.detail.scrollTop to get the current scroll position
    const scrollTop = event.detail.scrollTop;
    console.log('Scroll position:', scrollTop);

    // You can add your custom logic based on the scroll position
    if (scrollTop > 100) {
      // Do something when scrolled beyond a certain point
    }
  }

  async confirmExitApp() {
    if (this.alert_exist == false) {
      this.alert_exist = true;
      this.alert.confirmationAlert({
        title: 'Confirmation',
        text: 'Are You Sure Want To Exit The App?',
        action: 'warning',
        confirmBtnText: 'Yes, proceed',
        cancelBtnText: 'No, cancel',
        confirmCallback: () => {
          this.alert_exist = false;
          App.exitApp();
        },
        cancelCallback: () => {
          this.alert_exist = false;
        },
      });
    }
  }

  logout() {
    this.call_http_post({
      url: environment.API_URL + 'mastermodule/logout',
      data: {
        user_id: this.localStorage.getItem('user_id'),
        login_dt: this.localStorage.getItem('login_dt'),
        sid: this.get_session_id_login(),
      },
      successCb: (res) => {
        var result = res.data;
        // console.log({ result });
        // let { message } = result;
        // if message == "ok"
        this.clearUserSession();
        window.location.replace('/mod0101');
      },
    });
  }

  clearUserSession() {
    // Clear App PIN
    this.localStorage.removeItem(this.app_pin_key);

    this.localStorage.removeItem('token');

    this.localStorage.removeItem('user_id');
    this.localStorage.removeItem('user_type');

    this.localStorage.removeItem('user_name');
    this.localStorage.removeItem('designation');
    this.localStorage.removeItem('isAdmin');
    this.localStorage.removeItem('mobile');
    this.localStorage.removeItem('user_branch');
    this.localStorage.removeItem('user_region');
    this.localStorage.removeItem('user_staff_id');
    this.localStorage.removeItem('user_region_id');
    this.localStorage.removeItem('user_branch_id');

    this.cookies.delete('session_key');
  }

  //ROWS SHOWING FUNCTION
  showig_rows_count(maxRows: any, pageNum: any, totalRows: any) {
    //Default rows showing
    var end_index = maxRows * pageNum;
    var start_index = maxRows * pageNum - maxRows + 1;
    var string =
      'Showing ' +
      start_index +
      ' to ' +
      end_index +
      ' of ' +
      totalRows +
      ' entries';
    $('.rows_count').html(string);
  }

  getDT(dt: any) {
    let dtObj = new Date(dt);
    return dtObj.toLocaleDateString() + ' ' + dtObj.toLocaleTimeString();
  }
  getMonthName(m: number) {
    var month_names = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return month_names[m];
  }
  getMonthNameShort(m: number) {
    var month_names_short = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return month_names_short[m];
  }

  getDTperFormat(dt: any, format = 'dd/MM/yyyy hh:mm ap') {
    let dtObj = new Date(dt);
    format = format.replace(
      'yyyy',
      String(dtObj.getFullYear()).padStart(4, '0')
    );
    format = format.replace('MMMM', this.getMonthName(dtObj.getMonth()));
    format = format.replace('MMM', this.getMonthNameShort(dtObj.getMonth()));
    format = format.replace(
      'MM',
      String(dtObj.getMonth() + 1).padStart(2, '0')
    );
    format = format.replace('dd', String(dtObj.getDate()).padStart(2, '0'));
    format = format.replace('HH', String(dtObj.getHours()).padStart(2, '0'));
    format = format.replace('mm', String(dtObj.getMinutes()).padStart(2, '0'));
    let hr = dtObj.getHours();
    let hh = hr % 12;
    let apm = 'AM';
    if (hr >= 12) {
      apm = 'PM';
      if (hr == 12) hh = 12;
    }
    format = format.replace('hh', String(hh).padStart(2, '0'));
    format = format.replace('ss', String(dtObj.getSeconds()).padStart(2, '0'));
    format = format.replace('ap', apm);
    return format;
  }

  getUTCdateStr(dt: any) {
    let dtObj = new Date(dt);
    return (
      dtObj.getUTCFullYear() +
      '-' +
      String(dtObj.getUTCMonth() + 1).padStart(2, '0') +
      '-' +
      String(dtObj.getUTCDate()).padStart(2, '0') +
      ' ' +
      String(dtObj.getUTCHours()).padStart(2, '0') +
      ':' +
      String(dtObj.getUTCMinutes()).padStart(2, '0') +
      ':00'
    );
  }

  // should be done in backend
  validateAdmin() {
    return this.localStorage.getItem('isAdmin') == 'true';
    /*
    this.call_api_post("", {
      session_key: this.get_session_id_login()
    }).subscribe((res:any) => {
      var result = res.data
      return result['isAdmin'] == 1;
    })
    */
  }

  intToEnglish(number: any): string {
    var NS = [
      { value: 10000000, str: 'Crore' },
      { value: 100000, str: 'Lakh' },
      { value: 1000, str: 'Thousand' },
      { value: 100, str: 'Hundred' },
      { value: 90, str: 'Ninety' },
      { value: 80, str: 'Eighty' },
      { value: 70, str: 'Seventy' },
      { value: 60, str: 'Sixty' },
      { value: 50, str: 'Fifty' },
      { value: 40, str: 'Forty' },
      { value: 30, str: 'Thirty' },
      { value: 20, str: 'Twenty' },
      { value: 19, str: 'Nineteen' },
      { value: 18, str: 'Eighteen' },
      { value: 17, str: 'Seventeen' },
      { value: 16, str: 'Sixteen' },
      { value: 15, str: 'Fifteen' },
      { value: 14, str: 'Fourteen' },
      { value: 13, str: 'Thirteen' },
      { value: 12, str: 'Twelve' },
      { value: 11, str: 'Eleven' },
      { value: 10, str: 'Ten' },
      { value: 9, str: 'Nine' },
      { value: 8, str: 'Eight' },
      { value: 7, str: 'Seven' },
      { value: 6, str: 'Six' },
      { value: 5, str: 'Five' },
      { value: 4, str: 'Four' },
      { value: 3, str: 'Three' },
      { value: 2, str: 'Two' },
      { value: 1, str: 'One' },
    ];

    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return (
              this.intToEnglish(t) + ' ' + n.str + ' ' + this.intToEnglish(d)
            );
          } else {
            return this.intToEnglish(t) + ' ' + n.str;
          }
        }
      }
    }
    return result;
  }

  // To Convert Table in to PDF

  // async generatePDF(table_card_1,table_id,table_heading ='REPORT',table_name='REPORT',font_size=8) {
  //   if(this.alert_exist==false)
  //   {
  //     this.alert_exist=true
  //     const alert = await this.alertController.create({
  //       header: 'Confirmation',
  //       message: 'Are You Sure Want To Download The PDF ?',
  //       buttons: [
  //         {
  //           text: 'Cancel',
  //           role: 'cancel',
  //           cssClass: 'secondary',
  //           handler: () => {
  //             return
  //           }
  //         }, {
  //           text: 'OK',
  //           handler: () => {

  //             this.Confirm_generatePDF(table_card_1,table_id,table_heading ='REPORT',table_name='REPORT',font_size=8)

  //           }
  //         }
  //       ]
  //     });

  //     await alert.present();
  //     this.alert_exist=false
  //   }
  // }

  async generatePDF(
    table_card_1: string,
    table_id: string,
    table_heading = 'REPORT',
    table_name = 'REPORT',
    font_size = 8
  ) {
    if (this.alert_exist == false) {
      this.alert_exist = true;

      this.confirmationAlert({
        text: 'Are You Sure Want To Download The PDF ?',
        confirmCallback: () => {
          this.Confirm_generatePDF(
            table_card_1,
            table_id,
            table_heading,
            table_name,
            font_size
          );
          this.alert_exist = false;
        },
        cancelCallback: () => {
          this.warningAlert({
            text: 'cancelled',
            action: 'error',
          });
          this.alert_exist = false;
        },
      });
    }
  }

  Confirm_generatePDF(
    table_card_1: string,
    table_id: string,
    table_heading = 'REPORT',
    table_name = 'REPORT',
    font_size = 8
  ) {
    const doc = new jsPDF();
    const logoURL = this.set_brand_logo(); // Replace with the correct path to your company logo image
    doc.addImage(logoURL, 'JPEG', 10, 10, 40, 15);
    doc.setTextColor(100, 107, 111);
    // const leftFooterContent = '+91 9489715036';
    const leftFooterContent = '+91 ' + this.localStorage.getItem('cmp_contact');
    // const rightFooterContent = 'msktrans.com';
    const rightFooterContent = this.localStorage.getItem('cmp_website');

    const CurentDate = new Date().toISOString().substring(0, 10);
    const CurrentTime = new Date().toLocaleTimeString('en-US', {
      hour12: true,
    });
    const user_mobile = this.localStorage.getItem('user_name');

    const customFooter = (data: any) => {
      // Left footer
      doc.setFontSize(10);
      doc.text(leftFooterContent, 10, doc.internal.pageSize.height - 10);
      // Right footer
      doc.text(
        rightFooterContent,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10
      );
    };

    // Create an HTML structure for the card content
    // const cardContent = document.querySelector('#card');

    const cardData = document.getElementById(table_card_1);
    const tableData = [document.getElementById(table_id)];

    const cardTableParams = {
      theme: 'plain',
      startY: 38, // Adjust the Y coordinate to make space for the header
      body: cardData,
      styles: {
        fontSize: 8,
        halign: 'left', // Adjust text alignment as needed
        fontStyle: 'bold',
        cellWidth: 60, // Set a fixed column width
        cellSpace: 3,
      },
    };

    // to set table heading
    doc.setFontSize(16);
    doc.setTextColor(0);
    // (doc as any).setFont('bold');
    const captionText = table_heading;
    const textWidth =
      (doc.getStringUnitWidth(captionText) *
        (doc as any).internal.getFontSize()) /
      doc.internal.scaleFactor;
    const xOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(captionText, xOffset, 30);

    const tableParams = {
      // theme: 'plain',
      // startY: 60, // Adjust the Y coordinate to make space for the header
      addPageContent: customFooter,
      // addPageContent1: customHeader,
      fontStyle: 'bold',
      body: tableData,
      styles: {
        fontSize: font_size, // Set the font size for the table content
        halign: 'left', // Right-aligned body text
        fontStyle: 'bold',
        cellWidth: 'warp', // Set a fixed column width
      },

      headStyles: {
        // Customize styles for the table headings
        fillColor: [108, 11, 11], // Background color as RGB (red)
        textColor: [255, 255, 255], // Text color as RGB (white)
        fontSize: font_size,
        halign: 'center',
        cellWidth: 'warp', // Set a fixed column width
        fontStyle: 'bold',
      },
      tableWidth: 190, //'190' 'auto' or 'wrap' to adjust table width automatically or 'wrap' to fit content
      margin: { top: 20, left: 10 },
      // Your table data here
    };

    // Add your table content for multiple pages
    // const tableData = [document.getElementById('curr_table')];

    // Function to add the table to the PDF
    const addTableToPDF = () => {
      (doc as any).autoTable({ html: '#' + table_id, ...tableParams });
    };
    const addCardToPDF = (cardTable: HTMLElement | null, params: any) => {
      if (cardTable) {
        (doc as any).autoTable({ html: cardTable, ...params });
      }
    };
    addCardToPDF(cardData, cardTableParams);

    // Loop to add the table content
    for (let i = 0; i < tableData.length; i++) {
      addTableToPDF();

      if (i === tableData.length - 1) {
        // Define your middle footer content
        const middleFooterContent =
          'Printed on ' +
          (CurentDate.split('-')[2] +
            '-' +
            CurentDate.split('-')[1] +
            '-' +
            CurentDate.split('-')[0]) +
          ' at ' +
          CurrentTime +
          ' by ' +
          user_mobile +
          '';
        const middleFooterContent1 =
          '                            * End of Report *';
        doc.setFontSize(9);
        doc.text(middleFooterContent, 65, (doc as any).autoTableEndPosY() + 10);
        doc.text(
          middleFooterContent1,
          65,
          (doc as any).autoTableEndPosY() + 17
        );
      }
    }

    // Adding Page Numbers
    const pageCount = (doc as any).internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        '' + i,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 10,
        { align: 'center' }
      );
    }

    // Save or display the PDF
    doc.save(table_name + '.pdf');
  }

  Confirm_multi_generatePDF(
    table_cards: string[],
    table_ids: string[],
    table_headings = ['REPORT'],
    table_names = ['REPORT'],
    font_size = 8
  ) {
    const doc = new jsPDF();
    const logoURL = this.set_brand_logo(); // Replace with the correct path to your company logo image
    doc.addImage(logoURL, 'JPEG', 10, 10, 40, 15);
    doc.setTextColor(100, 107, 111);
    const leftFooterContent = '+91 9489715036';
    const rightFooterContent = 'msktrans.com';

    const CurentDate = new Date().toISOString().substring(0, 10);
    const CurrentTime = new Date().toLocaleTimeString('en-US', {
      hour12: true,
    });
    const user_mobile = this.localStorage.getItem('mobile');

    const customFooter = (data: any) => {
      // Left footer
      doc.setFontSize(10);
      doc.text(leftFooterContent, 10, doc.internal.pageSize.height - 10);
      // Right footer
      doc.text(
        rightFooterContent,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10
      );
    };

    for (let i = 0; i < table_ids.length; i++) {
      const cardData = document.getElementById(table_cards[i]);
      const tableData = document.getElementById(table_ids[i]);

      const cardTableParams = {
        theme: 'plain',
        startY: 38, // Adjust the Y coordinate to make space for the header
        body: cardData,
        styles: {
          fontSize: 8,
          halign: 'left', // Adjust text alignment as needed
          fontStyle: 'bold',
          cellWidth: 60, // Set a fixed column width
          cellSpace: 3,
        },
      };

      // to set table heading
      doc.setFontSize(16);
      doc.setTextColor(0);
      const captionText = table_headings[i];
      const textWidth =
        (doc.getStringUnitWidth(captionText) *
          (doc as any).internal.getFontSize()) /
        doc.internal.scaleFactor;
      const xOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(captionText, xOffset, 30);

      const tableParams = {
        addPageContent: customFooter,
        fontStyle: 'bold',
        body: tableData,
        styles: {
          fontSize: font_size,
          halign: 'left',
          fontStyle: 'bold',
          cellWidth: 'warp',
        },

        headStyles: {
          fillColor: [108, 11, 169],
          textColor: [255, 255, 255],
          fontSize: font_size,
          halign: 'center',
          cellWidth: 'warp',
          fontStyle: 'bold',
        },
        tableWidth: 190,
        margin: { top: 40, left: 10 },
      };

      // Function to add the table to the PDF
      const addTableToPDF = () => {
        (doc as any).autoTable({ html: '#' + table_ids[i], ...tableParams });
      };
      const addCardToPDF = (cardTable: HTMLElement | null, params: any) => {
        if (cardTable) {
          (doc as any).autoTable({ html: cardTable, ...params });
        }
      };
      addCardToPDF(cardData, cardTableParams);
      addTableToPDF();

      if (i === table_ids.length - 1) {
        const middleFooterContent =
          'Printed on ' +
          (CurentDate.split('-')[2] +
            '-' +
            CurentDate.split('-')[1] +
            '-' +
            CurentDate.split('-')[0]) +
          ' at ' +
          CurrentTime +
          ' by ' +
          user_mobile +
          '';
        const middleFooterContent1 =
          '                            * End of Report *';
        doc.setFontSize(9);
        doc.text(middleFooterContent, 65, (doc as any).autoTableEndPosY() + 10);
        doc.text(
          middleFooterContent1,
          65,
          (doc as any).autoTableEndPosY() + 17
        );
      }
    }

    // Adding Page Numbers
    const pageCount = (doc as any).internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        '' + i,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 10,
        { align: 'center' }
      );
    }

    // Save or display the PDF
    doc.save(table_names[0] + '.pdf'); // Assuming only one name for the PDF
  }

  // To convert a table to exel format
  async convertTableToCSV(tableId: string, fileName: string = 'Report') {
    if (this.alert_exist == false) {
      this.alert_exist = true;
      this.confirmationAlert({
        text: 'Are You Sure Want To Download The CSV File ?',
        confirmCallback: () => {
          this.Confirm_convertTableToCSV(tableId, fileName);
          this.alert_exist = false;
        },
        cancelCallback: () => {
          this.warningAlert({
            text: 'Cancelled',
            action: 'error',
          });
          this.alert_exist = false;
        },
      });
    }
  }

  Confirm_convertTableToCSV(tableId: string, fileName: string = 'report') {
    // Get the HTML table element by its ID
    const table = document.getElementById(tableId);

    // Check if the table exists
    if (!table) {
      console.error(`Table with ID "${tableId}" not found.`);
      return;
    }

    // Extract table data (rows and cells) and prepare CSV content
    const rows = table.querySelectorAll('tr');
    const csvContent = Array.from(rows, (row) =>
      Array.from(row.querySelectorAll('td, th'), (cell) =>
        (cell.textContent || '').trim()
      ).join(',')
    ).join('\n');

    // Create a blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a download link
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);

    // Append the link to the document body and trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // get a page follow form privilage

  get_privilage_function(onDone = () => {}) {
    var obj = {
      user_id: this.localStorage.getItem('user_id'),
      user_type: this.localStorage.getItem('user_type'),
    };

    this.call_http_post({
      url: environment.API_URL + 'mastermodule/get_privilage_function',
      data: obj,
      successCb: (res: any) => {
        let result = res.data;
        // console.log('privilage _function_res :', { result });
        let { dt = [], message } = result;
        let list_of_page_prv_objs: {
          page_id: number;
          modname: string;
          pagename: string;
          page_allow: string | number;
          view_allow: string | number;
          print_allow: string | number;
          add_allow: string | number;
          edit_allow: string | number;
          delete_allow: string | number;
        }[] = dt;
        // only allowed / viewable active pages
        list_of_page_prv_objs = list_of_page_prv_objs.filter(
          (prv) => prv.page_allow == '1' && prv.view_allow == '1'
        );

        this.privilage_array = list_of_page_prv_objs.map((pg_prv) =>
          String(pg_prv.page_id)
        );
        console.log(this.privilage_array, 'common privilage_array');

        this.saved_nav_stack = list_of_page_prv_objs;

        onDone();
      },
    });
    //   this.previewMenubar()
  }

  verify_cur_loading_pg_privilege() {
    this.call_http_post({
      url: environment.API_URL + 'mastermodule/verify_pg_privilege',
      data: {
        modname: this.DefaultModuleName,
        user_id: this.localStorage.getItem('user_id'),
        user_type: this.localStorage.getItem('user_type'),
      },
      successCb: (res: any) => {
        let result = res.data;
        console.log('page privilage verifying :', { result });
        let { dt = [], message } = result;
      },
    });
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  alert = {
    warningAlert: this.warningAlert,
    confirmationAlert: this.confirmationAlert,
  };

  private warningAlert(props: {
    title?: string;
    text: string;
    time?: any;
    action?: 'success' | 'update' | 'delete' | 'warning' | 'error';
    iconColor?: string;
    iconHtml?: string;
    btnColor?: string;
    btnText?: string;
    callback?: () => void;
    // customClass?: {
    //   [key: string]: string
    // }
  }) {
    const {
      title,
      text,
      time,
      action,
      iconColor,
      btnColor,
      btnText,
      iconHtml,
      callback,
    } = props;
    let sweetAlertIcon: SweetAlertIcon = 'info';
    let setColor: string;
    let setIconHtml: string;
    let setTitle: string;

    switch (action) {
      case 'success':
        setTitle = 'Success';
        setIconHtml =
          '<i class="fa-solid fa-check fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#30a702';
        sweetAlertIcon = 'success';
        break;
      case 'update':
        setTitle = 'Updated';
        setIconHtml =
          '<i class="fa-solid fa-check fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#0000FF';
        sweetAlertIcon = 'success';
        break;
      case 'delete':
        setTitle = 'Deleted';
        setIconHtml =
          '<i class="fa-regular fa-trash-can fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#FF0000';
        sweetAlertIcon = 'success';
        break;
      case 'warning':
        setTitle = 'Warning!';
        setIconHtml =
          '<i class="fa-solid fa-exclamation fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#F6BE00';
        sweetAlertIcon = 'warning';
        break;
      case 'error':
        setTitle = 'Error!';
        setIconHtml =
          '<i class="fa-solid fa-x fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#FF0000';
        sweetAlertIcon = 'error';
        break;
      default:
        setTitle = '';
        setIconHtml =
          '<i class="fa-solid fa-check fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#6C0BA9';
        sweetAlertIcon = 'info';
        break;
    }

    setColor = iconColor || setColor;

    let iconProps: { iconHtml?: string; icon?: SweetAlertIcon } = {
      iconHtml: iconHtml || setIconHtml,
    };
    if (!iconProps.iconHtml) {
      iconProps = { icon: sweetAlertIcon };
    }

    Swal.fire({
      title: title || setTitle,
      html: text,
      timer: time,
      iconColor: setColor,
      confirmButtonColor: btnColor || '#6C0BA9',
      confirmButtonText: btnText || 'OK',
      customClass: {
        popup: 'cus-sweet-alert',
        // title: 'custom-title-class',
        // icon: 'custom-icon-class',
        // Add other classes as needed
      },
      ...iconProps,
      // customClass: {
      //   icon: 'no-border'
      // },
    }).then(callback);

    document.body.classList.remove('swal2-height-auto'); // to align modal vertically centered
  }

  private confirmationAlert(props: {
    title?: string;
    text: string;
    action?: 'delete' | 'warning' | 'logout';
    iconColor?: string;
    iconHtml?: string;
    confirmBtnText?: string;
    confirmBtnColor?: string;
    cancelBtnText?: string;
    cancelBtnColor?: string;
    confirmCallback?: () => void;
    cancelCallback?: () => void;
  }) {
    const {
      title,
      text,
      action,
      iconColor,
      iconHtml,
      confirmBtnText,
      confirmBtnColor,
      cancelBtnText,
      cancelBtnColor,
      confirmCallback = () => {},
      cancelCallback = () => {},
    } = props;
    let sweetAlertIcon: SweetAlertIcon = 'question';
    let setColor: string;
    let setIconHtml: string;

    switch (action) {
      case 'delete':
        setIconHtml =
          '<i class="fa-regular fa-trash-can fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#FF0000';
        break;
      case 'warning':
        setIconHtml =
          '<i class="fa-solid fa-exclamation fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#F6BE00';
        sweetAlertIcon = 'warning';

        break;
      case 'logout':
        setIconHtml =
          '<i class="fa-solid fa-arrow-right-from-bracket fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#6C0BA9';
        break;
      default:
        setIconHtml =
          '<i class="fa-solid fa-exclamation fa-shake" style="--fa-animation-duration: 2s; --fa-animation-iteration-count: 1;"></i>';
        setColor = '#6C0BA9';
        break;
    }

    setColor = iconColor || setColor;

    let iconProps: { iconHtml?: string; icon?: SweetAlertIcon } = {
      iconHtml: iconHtml || setIconHtml,
    };
    if (!iconProps.iconHtml) {
      iconProps = { icon: sweetAlertIcon };
    }

    Swal.fire({
      title: title || 'Are you sure?',
      html: text,
      iconColor: setColor,
      showCancelButton: true,
      confirmButtonText: confirmBtnText || 'Confirm',
      confirmButtonColor: confirmBtnColor || '#6C0BA9',
      cancelButtonText: cancelBtnText || 'Cancel',
      cancelButtonColor: cancelBtnColor,
      // reverseButtons: true,
      customClass: {
        popup: 'cus-sweet-alert',
        // title: 'custom-title-class',
        // icon: 'custom-icon-class',
        // Add other classes as needed
      },
      ...iconProps,
    }).then((result) => {
      if (result.isConfirmed) {
        confirmCallback();
      } else if (
        result.isDismissed &&
        result.dismiss === Swal.DismissReason.cancel
      ) {
        cancelCallback();
      }
    });
    document.body.classList.remove('swal2-height-auto'); // to align modal vertically centered
  }

  // Define showResultAlert function
  showResultAlert(title: string, text: string, icon: SweetAlertIcon) {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
    });
    document.body.classList.remove('swal2-height-auto'); // to align modal vertically centered
  }

  // // Define showSweetAlert_forDelete function
  // showSweetAlert_forDelete(title: string, text: string) {
  //   const handleResult = (result: any) => {
  //     if (result.isConfirmed) {
  //       showResultAlert("Deleted!", "Your file has been deleted.", "success");
  //     } else if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
  //       showResultAlert("Cancelled", "Your file is safe", "error");
  //     }
  //     document.body.classList.remove('swal2-height-auto');
  //   };

  //   Swal.fire({
  //     title: title,
  //     text: text,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel!",
  //     reverseButtons: true
  //   }).then(handleResult);
  // }

  relational_date_validation(startDate: any, endDate: any) {
    if (new Date(startDate) <= new Date(endDate)) {
      return true;
    } else {
      // if (new Date(startDate) > new Date(endDate))
      return false;
    }
  }

  validate_aadhaar_no(txt: string) {
    let aadhar_regex = /^[2-9]{1}[0-9]{11}$/;
    return !aadhar_regex.test(txt);
  }

  validate_pan_no(txt: string) {
    let pan_regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
    return !pan_regex.test(txt);
  }

  validate_email(txt: string) {
    let email_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
    // let email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return email_regex.test(String(txt));
  }

  validate_phone_no(txt: string) {
    // Check if it has exactly 10 digits and is not all zeros
    return /^\d{10}$/.test(txt) && txt !== '0000000000';
  }

  validate_fileupload(
    file: File,
    valid_props: {
      types?: string[];
      min_size?: number;
      max_size?: number;
      accepted_types?: string;
    } = {}
  ) {
    valid_props.min_size = valid_props.min_size || 0.125;
    valid_props.max_size = valid_props.max_size || this.MAX_FILESIZE_COMMON;
    valid_props.types = valid_props.types || ['image/*, application/pdf'];
    valid_props.accepted_types = valid_props.accepted_types || 'Images, PDF';

    let file_size = file.size;
    let file_type = file.type;

    if (file_size == 0 || file_size < valid_props.min_size) {
      return { status: false, message: 'Invalid File Size' };
    }

    if (file_size > valid_props.max_size) {
      return {
        status: false,
        message:
          'Uploaded File Size is more than allowed limit of ' +
          valid_props.max_size / 1024 +
          'KB',
      };
    }

    let wildCardFileTypes = valid_props.types.filter((type_txt) =>
      type_txt.includes('*')
    );
    if (wildCardFileTypes.length > 0) {
      let nonWildCardFileTypes = valid_props.types.filter(
        (type_txt) => !type_txt.includes('*')
      );
      if (!nonWildCardFileTypes.includes(file_type)) {
        let fileTypeNotAccepted = true;
        for (let testFileType of wildCardFileTypes) {
          let checkTypeStr = testFileType.substring(
            0,
            testFileType.indexOf('/')
          );
          // console.log({ checkTypeStr });
          let fTypeRegex = new RegExp(
            `^${checkTypeStr}\/[\\-\\.\\w]+(?:\\+[\\-\\.\\w]+)?`
          ); // eg: /image\//
          // console.log({ fTypeRegex });

          let fileTypeAccepted = fTypeRegex.test(file_type);
          if (fileTypeAccepted) {
            fileTypeNotAccepted = false;
            break;
          }
        }
        if (fileTypeNotAccepted) {
          return {
            status: false,
            message:
              'Uploaded File Type is not accepted. Accepted Types: ' +
              valid_props.accepted_types,
          };
        }
      }
    } else {
      if (!valid_props.types.includes(file_type)) {
        return {
          status: false,
          message:
            'Uploaded File Type is not accepted. Accepted Types: ' +
            valid_props.accepted_types,
        };
      }
    }

    return { status: true };
  }

  // to check if the text contains only alpabets (a-z) or (A-Z)
  check_only_alphabet(id: any) {
    var text = $(id).val();
    var regapha = /^[A-Za-z]+$/;
    return regapha.test(String(text)) || text == '';
  }

  // only allow ALphapets
  keyPressAlphaNumeric(event: any) {
    var inp = String.fromCharCode(event.keyCode);

    if (/^[a-zA-Z\s]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  check_only_wholenumber(txt: string) {
    var num_reg = /^[0-9]+$/;
    return num_reg.test(txt);
  }

  check_only_decimalnumber(txt: string) {
    var num_reg = /^\d+(\.\d+)?$/;
    return num_reg.test(txt);
  }

  // Only Integer Numbers
  keyPressNumbers(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  // Only Integer Numbers and Dot
  keyPressNumbersAndDot(event: any) {
    // var charCode = event.which ? event.which : event.keyCode;
    var inp = String.fromCharCode(event.keyCode);
    if (/^[\.0-9]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  postData_with_stringUrl(
    url: string,
    data: any,
    stringData: any,
    responseType: any
  ): Observable<any> {
    const httpOptions = {
      url: url,
      method: 'POST',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.get_session_id_login(),
        modname: stringData,
      }),
      responseType: responseType,
    };

    return this.http.post(url, data, httpOptions);
  }

  // to check file Size

  validateFileSize2Mp(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (files && files.length > 0) {
      const file = files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
      // const maxSizeInBytes = 200 * 1024; // 200 KB

      if (file.size > maxSizeInBytes) {
        this.alert.warningAlert({
          text: 'File Size Must be Less than 2 MB.',
          action: 'warning',
          callback: () => {},
        });
        // Clear the file input to allow the user to select a different file
        fileInput.value = '';

        return false;
      } else {
        return true;
      }
    }
  }

  validateFileSize200Kb(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (files && files.length > 0) {
      const file = files[0];
      // const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
      const maxSizeInBytes = 200 * 1024; // 200 KB

      if (file.size > maxSizeInBytes) {
        this.alert.warningAlert({
          text: 'File Size Must be Less than 200 KB.',
          action: 'warning',
          callback: () => {},
        });
        // Clear the file input to allow the user to select a different file
        fileInput.value = '';

        return false;
      } else {
        return true;
      }
    }
  }

  validateFileSize1Mp(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (files && files.length > 0) {
      const file = files[0];
      const maxSizeInBytes = 1 * 1024 * 1024; // 2 MB

      if (file.size > maxSizeInBytes) {
        this.alert.warningAlert({
          text: 'File Size Must be Less than 1 MB.',
          action: 'warning',
          callback: () => {},
        });
        // Clear the file input to allow the user to select a different file
        fileInput.value = '';

        return false;
      } else {
        return true;
      }
    }
  }

  // to open file in new tab

  view_privew_url(doc: any) {
    var url = environment.API_URL + 'media' + '/' + doc;
    window.open(url, '_blank');
  }

  view_privew_url_src_only(doc: any) {
    var url = environment.API_URL + 'media' + '/' + doc;
    return url;
  }

  refresh_captcha(options: {
    modname: string;
    captchaInputSelector: string;
    captchaImgSelector: string;
    onCaptchaLoad?: () => void;
    onCaptchaLoadErr?: (err: any) => void;
  }) {
    let {
      modname,
      captchaInputSelector, // = '#capt',
      captchaImgSelector = "[name='captcha2']",
      onCaptchaLoad = () => {},
      onCaptchaLoadErr = (err) => {},
    } = options;

    console.log(modname, 'Checking ');
    if (captchaInputSelector) $(captchaInputSelector).val('');

    let cpt_key = this.cpt_views[modname]['cptid-keyname'];
    console.log(
      'test cpt id :',
      this.cpt_views[modname],
      this.cpt_session_keys[modname],
      this.localStorage.getItem(cpt_key)
    );
    // console.log('test', this.api.localStorage.getItem(cpt_key))
    fetch(environment.API_URL + 'captcha/captcha_image', {
      // credentials: 'include',  // to use set-cookie header
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // to reuse the session used to load previous captcha if page already loaded
        // to reuse the session used in verify mobile number section of registration pages
        last_used_cptid: this.localStorage.getItem(cpt_key) || '',
        modname,
      }),
    })
      .then((res) => {
        this.cpt_session_keys[modname] = res.headers.get('x-cptid') || '';
        if (environment.production) {
          // console.log('test cookie :', this.cookies.get('sid'));
          // this.cpt_session_key = this.cookies.get(cpt_key);
        }
        console.log(this.cpt_session_keys);
        this.localStorage.setItem(cpt_key, this.cpt_session_keys[modname]);
        return res.blob();
      })
      .then((blob) => {
        let URLcreator = window.URL || window.webkitURL;
        let captImgTempUrl = URLcreator.createObjectURL(blob);
        let $capt_img = $(captchaImgSelector);
        $capt_img.attr('src', captImgTempUrl);
        $capt_img[0].onload = () => {
          URLcreator.revokeObjectURL(captImgTempUrl);
          onCaptchaLoad();
        };
      })
      .catch((err) => {
        console.log(err);
        onCaptchaLoadErr(err);
      });
  }

  verify_captcha(options: {
    modname: string;
    captchaInputSelector: string;
    captchaInputVal: string;
    captchaImgSelector: string;
    onCaptchaVerified?: (res: any) => void;
    onComplete?: () => void;
  }) {
    const {
      modname,
      captchaInputSelector,
      captchaInputVal,
      captchaImgSelector,
      onCaptchaVerified = (res) => {},
      onComplete = () => {},
    } = options;
    let errCallBk = this.common_errorCb;

    let cpt_key = this.cpt_views[modname]['cptid-keyname'];
    let captcha_data = {
      captcha_entered: captchaInputVal,
      cpt_id: this.localStorage.getItem(cpt_key) || '',
      modname,
    };
    console.log(captcha_data);

    this.call_http_post({
      url: environment.API_URL + 'captcha/verify_captcha',
      data: captcha_data,
      successCb: (result) => {
        try {
          let { verified = false, message, session = 'valid' } = result.data;
          if (!verified) {
            onComplete();
            if (message) {
              // Show Captcha Input Validation Error Message
              this.alert.warningAlert({
                text: message,
                action: 'error',
                callback: () => {
                  setTimeout(function () {
                    $(captchaInputSelector).focus();
                  }, 500);
                },
              });
            } else {
              let expiredCaptcha = session == 'invalid';
              this.alert.warningAlert({
                text: expiredCaptcha ? 'Captcha Expired' : 'Invalid Captcha',
                action: 'error',
                callback: () => {
                  if (expiredCaptcha) {
                    this.refresh_captcha({
                      modname,
                      captchaInputSelector,
                      captchaImgSelector,
                    });
                  }
                  setTimeout(function () {
                    $(captchaInputSelector).focus();
                  }, 500);
                },
              });
            }
            return;
          }
        } catch (error) {
          errCallBk(error);
          onComplete();
        }
        onCaptchaVerified(result);
      },
      afterError: () => {
        onComplete();
      },
    });
  }

  set_brand_logo(id = 'logo_image') {
    var cmp_logo = this.localStorage.getItem('cmp_logo');
    var media_url = this.localStorage.getItem('media_url');

    // Create a URL for the image blob using createObjectURL

    try {
      const imageElement = document.getElementById(id) as HTMLImageElement;

      const imageURL = environment.API_URL + media_url + '/' + cmp_logo;
      // Set the 'src' attribute of the <img> element to the URL
      imageElement.src = imageURL;

      return environment.API_URL + media_url + '/' + cmp_logo;
    } catch {
      return environment.API_URL + media_url + '/' + cmp_logo;
    }

    return environment.API_URL + media_url + '/' + cmp_logo;
  }

  set_brand_logo2(id = 'logo_image') {
    var cmp_logo = this.localStorage.getItem('cmp_logo');
    var media_url = this.localStorage.getItem('media_url');

    // Create a URL for the image blob using createObjectURL

    try {
      const imageElement = document.getElementById(id) as HTMLImageElement;

      const imageURL = environment.API_URL + media_url + '/' + cmp_logo;
      // Set the 'src' attribute of the <img> element to the URL
      imageElement.src = imageURL;

      return environment.API_URL + media_url + '/' + cmp_logo;
    } catch {
      return environment.API_URL + media_url + '/' + cmp_logo;
    }

    return environment.API_URL + media_url + '/' + cmp_logo;
  }

  setCSSVariable(variable: string, value: string): void {
    // Access the root element (:root) and set the CSS variable
    document.documentElement.style.setProperty(variable, value);
  }
  printContent(
    currentDate, BillingPartyId, selParty, BillNo, EmailAddress , getbilling:any, getbillinglist:any,
     BillingFormat, startDate, endDate, containerNo,totalWords
   
  ) {
    const getbillingdata = getbilling;
    const getbillinglistdata = getbillinglist;
888
    const updatedBillingList = getbillinglistdata.map(item => ({
      ...item,
      startingDate: startDate,
      endingDate: endDate,
      containerNum:containerNo,
    }));
    const totalAmountSum = updatedBillingList.reduce((sum, item) => sum + (parseFloat(item.TotalAmount) || 0), 0);
    console.log(updatedBillingList,'updatedBillingList')
    let printContent = '';

   if(BillingFormat == 'BillFormat1'){
    printContent = `
<html>
<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />

  <style>
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
    }
    .content-container {
  border: 2px solid black; /* Border around the content */
  padding: 0px; /* Equal padding around the content */
  margin: 0px; /* Margin outside the border */
  width: 100%;
  box-sizing: border-box; /* Ensure the padding and border are included in the width */
}


    .main-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid black;
    }

    .header img {
      width: 90px;
      height: auto;
    }

    .headerContent {
      text-align: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .headerContent h1 {
      font-size: 26px;
      font-weight: bold;
      margin: 5px 0;
      text-transform: uppercase;
    }

    .headerContent h3 {
      font-size: 16px;
      font-weight: unbold;
      text-decoration: underline;
      margin: 5px 0;
    }

    .headerContent p {
      font-size: 12px;
      margin: 5px 0;
      text-align: center;
      max-width: 500px;
    }

    .godSymbol img {
      width: 40px;
      height: auto;
    }

    .headerContact {
      text-align: right;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.5;
    }

    @media screen and (max-width: 768px) {
      .main-header {
        flex-direction: column;
        text-align: center;
      }

      .header, .headerContact {
        text-align: center;
      }

      .headerContact h5 {
        font-size: 12px;
      }

      .godSymbol img {
        width: 30px;
      }
    }

    .party {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid black;
      height: 100px;
      margin-top: 5px;
    }

    .party_To {
      margin: 0px 10px;
      border-right: 1px solid black;
    }

    .party_Num p {
      padding: 15px 0px 0px 3px;
      margin: 0px 10px;
    }

    .footer {
      margin-top: 100px;
    }

    .account_details {
      border-bottom: 1px solid black;
      padding-bottom: 3px;
    }

    .account_details h5 {
      padding: 0px;
      margin: 0px;
      padding: 0px 10px;
    }

    .Booking_sign {
      padding: 0px;
      margin: 10px;
      text-align: right;
      padding-right: 20px;
    }

    .Booking_sign p {
      text-align: right;
      padding: 0px;
      margin: 10px;
      padding-right: 10px;
    }
   
    .total{
     
    }

    .total p {
      text-align: right;
      padding: 0px;
      margin: 0px;
      padding-right: 20px;
    }

    table {
      width: 98%;
      border-collapse: collapse;
      table-layout: fixed;
      margin-left: auto;
      margin-right: auto;

    }
    table th, table td {
      border: 1px solid black;
      padding: 2px;
      text-align: center;
      font-size: 12px;
      word-wrap: break-word;
    }

    table th {
      background-color: #f4f4f4;
      font-weight: bold;
    }

    tfoot td {
      font-weight: bold;
      background-color: #f4f4f4;
    }
  </style>
</head>
<body>

  <div class="content-container">
    <div class="main-header">
      <div class="header">
        <img src="../../../assets/icon/favicon.png" alt="Header Image" />
      </div>

      <div class="headerContent">
        <div class="godSymbol">
          <img src="../../../assets/icon/godsymbols.png" alt="God Image" />
        </div>
        <h3>TRANSPORT BILL</h3>
        <h1><b>MSK LORRY BOOKING OFFICE</b></h1>
        <p>
          3/90-10/1, North Street, Thiruchendur Main Road, Muthaiyapuram,
          Tuticorin - 628 005.<br/> E-mail: msktrans@yahoo.com, msk24trans@gmail.com.
          Web: www.msktrans.in<br/> Pan No.: BBHPK2416F GSTIN: 33BHPK2416F3Z2
        </p>
      </div>

      <div class="headerContact">
        <h5>
          Telephone: 0461-2355225<br />
          Cell: 94425 65394 <br />
          94425 55394 <br />
          94449 65394 <br />
          94438 97888 <br />
          94424 65394 <br />
          94456 65394
        </h5>
      </div>
    </div>

    <div class="party">
      <div class="party_To" style="width:65%">
        <p>To. M/S. <br/><span style="padding-left:80px"> ${selParty} </span>
        <span style="padding-left:80px">${EmailAddress}</span></p>
      </div>
      <div class="party_Num">
        <p><span style="display:inline-block; min-width:50px;">No</span>: ${BillNo}</p>
        <p><span style="display:inline-block; min-width:50px;">Date</span>: ${currentDate} </p>
       
      </div>
    </div><br>

    <div class="rich_content">
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Vehicle No</th>
            <th>Container No</th>
            <th>LoadType </th>
            <th>Place From</th>
            <th>Place To</th>
            <th>Date From</th>
            <th>Date To</th>
            <th>Status</th>
            <th>Freight</th>
            <th>Weighment</th>
            <th>LiftOn</th>
            <th>TollGate</th>
            <th>Loading / Unloading</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
            <tr>
             ${updatedBillingList.map((item,index) => `
              <tr>
                <td>${index+1}</td>
                <td>${item.vehicle_id__RegisterNo}</td>
                <td>${item.TripContainer}</td>
                <td>${item.PoTableId__LoadTypeId__LoadTypeName}</td>
                <td>${item.PoTableId__from_location_id__LocationName}</td>
                <td>${item.PoTableId__to_location_id__ToLocation}</td>
                <td>${item.PoTableId__StartDate}</td>
                <td>${item.EndDate}</td>
                <td>${item.LatestTripStatus}</td>
                <td>${item.Freight}</td>
                <td>${item.Weight}</td>
                <td>${item.LiftOn}</td>
                <td>${item.TollGate}</td>
                <td>${item.load_unload_charges}</td>
                <td>${item.TotalAmount}</td>
              </tr>
            `).join('')}

            </tr>
        </tbody>
      </table>
    </div><br>

    <div class="total">
      <h3 style="padding: 0px;margin: 0px;text-align: center;
      padding-left: 280px;">Total:${totalAmountSum.toFixed(2)}</h3><br>
        <p style="font-size:10px;"><b> </b></p>
    </div>
   
    <div class="footer">
      <div></div>  
      <div class="account_details">
        <h5>Note:THE GST AMOUNT WILL BE PAID BY THE PARTY</h5><br>
        <h5>${totalWords}</h5>
        <h5>AC No: 70380200000367   IFSC CODE: BARB0VJTUTI</h5>
        <h5>NAME: MSK LORRY BOOKING OFFICE</h5>
        <h5>BANK: BANK OF BARODA</h5>
        <h5>BRANCH: DAMODHARAN NAGAR TUTICORIN</h5><br>
      </div><br>
      <div class="Booking_sign">
        <br><h5 style="margin-top: 10px;">For MSK LORRY BOOKING OFFICE</h5>
        <br><p style="margin-top: 10px;">Signature</p><br><br>
      </div>
    </div>
  </div>

</body>
</html>`
  }
  else{
   printContent = `
<html>
<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />

  <style>
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
    }

    .content-container {
  border: 2px solid black; /* Border around the content */
  padding: 0px; /* Equal padding around the content */
  margin: 0px; /* Margin outside the border */
  width: 100%;
  box-sizing: border-box; /* Ensure the padding and border are included in the width */
}


    .main-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid black;
    }

    .header img {
      width: 90px;
      height: auto;
    }

    .headerContent {
      text-align: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .headerContent h1 {
      font-size: 25px;
      font-weight: bold;
      margin: 5px 0;
      text-transform: uppercase;
    }

    .headerContent h3 {
      font-size: 16px;
      font-weight: unbold;
      text-decoration: underline;
      margin: 5px 0;
    }

    .headerContent p {
      font-size: 12px;
      margin: 5px 0;
      text-align: center;
      max-width: 500px;
    }

    .godSymbol img {
      width: 40px;
      height: auto;
    }

    .headerContact {
      text-align: right;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.5;
    }

    @media screen and (max-width: 768px) {
      .main-header {
        flex-direction: column;
        text-align: center;
      }

      .header, .headerContact {
        text-align: center;
      }

      .headerContact h5 {
        font-size: 12px;
      }

      .godSymbol img {
        width: 30px;
      }
    }

    .party {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid black;
      height: 90px;
      margin-top: 5px;
    }

    .party_To {
      margin: 0px 10px;
      border-right: 1px solid black;
    }

    .party_Num p {
      padding: 15px 0px 0px 3px;
      margin: 0px 10px;
    }
   
    .details {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid black;
      height: 110px;
      margin-top: 8px;
    }

    .details_To {
      margin: 0px 10px;
      /*border-right: 1px solid black*/;
    }

    .details_Num h4 {
      padding: 15px 0px 0px 3px;
      margin: 0px 10px;
    }

    .details2 {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid black;
      height: 110px;
      margin-top: 2px;
    }

    .details2_To {
      margin: 0px 10px;
      /*border-right: 1px solid black*/;
    }

    .details2_Num h4 {
      padding: 15px 0px 0px 3px;
      margin: 0px 10px;
    }

    .footer {
      margin-top: 10px;
    }

    .account_details {
      border-bottom: 1px solid black;
      padding-bottom: 3px;
    }

    .account_details h5 {
      padding: 0px;
      margin: 0px;
      padding: 0px 10px;
    }

    .Booking_sign {
      padding: 0px;
      margin: 5px;
      text-align: right;
      padding-right: 20px;
    }

    .Booking_sign p {
      text-align: right;
      padding: 0px;
      margin: 10px;
      padding-right: 10px;
    }
   

    table {
      width: 98%;
      border-collapse: collapse;
      table-layout: fixed;
      margin-left: auto;
      margin-right: auto;

    }

    table th, table td {
      border: 1px solid black;
      padding: 5px;
      text-align: center;
      font-size: 12px;
      word-wrap: break-word;
    }

    table th {
      background-color: #f4f4f4;
      font-weight: bold;
    }

    tfoot td {
      font-weight: bold;
      background-color: #f4f4f4;
    }
  </style>
</head>
<body>

  <div class="content-container">
    <div class="main-header">
      <div class="header">
        <img src="../../../assets/icon/favicon.png" alt="Header Image" />
      </div>

      <div class="headerContent">
        <div class="godSymbol">
          <img src="../../../assets/icon/godsymbols.png" alt="God Image" />
        </div>
        <h3>TRANSPORT BILL</h3>
        <h1><b>MSK LORRY BOOKING OFFICE</b></h1>
        <p>
          3/90-10/1, North Street, Thiruchendur Main Road, Muthaiyapuram,
          Tuticorin - 628 005.<br/> E-mail: msktrans@yahoo.com, msk24trans@gmail.com.
          Web: www.msktrans.in<br/> Pan No.: BBHPK2416F GSTIN: 33BHPK2416F3Z2
        </p>
      </div>

      <div class="headerContact">
        <h5>
          Telephone: 0461-2355225<br />
          Cell: 94425 65394 <br />
          94425 55394 <br />
          94449 65394 <br />
          94438 97888 <br />
          94424 65394 <br />
          94456 65394
        </h5>
      </div>
    </div>

    <div class="party">
      <div class="party_To" style="width:65%">
        <p>To. M/S. <br/>
        <span style="padding-left:80px">${selParty} <br/> </span>
        <span style="padding-left:80px">${EmailAddress}</span></p>
      </div>
      <div class="party_Num">
        <p>No.: ${BillNo} </p>
        <p>Date:  ${currentDate} </p>
      </div>
    </div><br/>

    <div class="rich_content">
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Vehicle Number</th>
            <th>Container Number</th>
            <th>Load Type </th>
          </tr>
        </thead>
        <tbody>
        ${updatedBillingList.map((item,index) => `
            <tr>
              <td>${index+1}</td>
              <td>${item.vehicle_id__RegisterNo}</td>
                <td>${item.TripContainer}</td>
                <td>${item.PoTableId__LoadTypeId__LoadTypeName}</td>
            </tr>`).join('')}
        </tbody>
      </table>
    </div>
   
     <div class="details">
  <div class="details_To" style="width:65%">
    <h5><span style="display:inline-block; min-width:150px;">Place From</span>: ${getbillinglistdata[0].PoTableId__from_location_id__LocationName} </h5>
    <h5><span style="display:inline-block; min-width:150px;">Date From</span>:${getbillinglistdata[0].PoTableId__StartDate} </h5>
    <h5><span style="display:inline-block; min-width:150px;">Status</span>: ${getbillinglistdata[0].LatestTripStatus}</h5>
  </div>
  <div class="details_Num">
    <h5><span style="display:inline-block; min-width:130px; margin-left: -80px;">Place To</span>:${getbillinglistdata[0].PoTableId__to_location_id__ToLocation} </h5>
    <h5><span style="display:inline-block; min-width:130px;margin-left: -80px;">Date To</span>: ${getbillinglistdata[0].EndDate}</h5>
  </div>
</div>

<div class="details2">
  <div class="details2_To" style="width:65%">
    <h5><span style="display:inline-block; min-width:150px;">Freight Charges</span>:${getbillinglistdata[0].Freight} </h5>
    <h5><span style="display:inline-block; min-width:150px;">Weighment Charges</span>: ${getbillinglistdata[0].Weight}</h5>
    <h5><span style="display:inline-block; min-width:150px;">Lift On</span>:${getbillinglistdata[0].LiftOn} </h5>
  </div>
  <div class="details2_Num">
    <h5><span style="display:inline-block; min-width:130px;margin-left: -80px;">TollGate</span>:${getbillinglistdata[0].TollGate}</h5>
    <h5><span style="display:inline-block; min-width:130px;margin-left: -80px;">Loading/Unloading</span>:${getbillinglistdata[0].load_unload_charges} </h5>
    <h5><span style="display:inline-block; min-width:130px;margin-left: -80px;">Total</span>: ${getbillinglistdata[0].TotalAmount}</h5>
  </div>
</div>

   
    <div class="footer">
      <div class="account_details">
        <h5>Note:THE GST AMOUNT WILL BE PAID BY THE PARTY</h5><br>
        <h5>${totalWords}</h5>
        <h5>AC No: 70380200000367   IFSC CODE: BARB0VJTUTI</h5>
        <h5>NAME: MSK LORRY BOOKING OFFICE</h5>
        <h5>BANK: BANK OF BARODA</h5>
        <h5>BRANCH: DAMODHARAN NAGAR TUTICORIN</h5><br>
      </div><br>
      <div class="Booking_sign">
        <br><h5 style="margin-top: 2px;">For MSK LORRY BOOKING OFFICE</h5>
        <br><p style="margin-top: 5px;">Signature</p>
      </div>
    </div>
  </div>

</body>
</html>
  
    `}
    // Open new window or iframe and print
    const printWindow = window.open('', '', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.print();
      };
    }
  }

  async generate_amount_based_PDF(
    table_card_1,
    table_id,
    table_heading = 'REPORT',
    table_name = 'REPORT',
    font_size = 8
  ) {
    if (this.alert_exist == false) {
      this.alert_exist = true;
  
      this.confirmationAlert({
        text: 'Are You Sure Want To Download The PDF ?',
        confirmCallback: () => {
          this.Confirm_generate_amount_based_PDF(
            table_card_1,
            table_id,
            table_heading,
            table_name,
            font_size
          );
          this.alert_exist = false;
        },
        cancelCallback: () => {
          this.warningAlert({
            text: 'cancelled',
            action: 'error',
          });
          this.alert_exist = false;
        },
      });
    }
  }
  
  Confirm_generate_amount_based_PDF(
    table_card_1,
    table_id,
    table_heading = 'REPORT',
    table_name = 'REPORT',
    font_size = 8
  ) {
    const doc = new jsPDF();
    const logoURL = this.set_brand_logo(); // Replace with the correct path to your company logo image
    doc.addImage(logoURL, 'JPEG', 10, 10, 40, 15);
    doc.setTextColor(100, 107, 111);
    const leftFooterContent = '+91 ' + this.localStorage.getItem('cmp_contact');
    const rightFooterContent = this.localStorage.getItem('cmp_website');
  
    const CurentDate = new Date().toISOString().substring(0, 10);
    const CurrentTime = new Date().toLocaleTimeString('en-US', {
      hour12: true,
    });
    const user_mobile = this.localStorage.getItem('user_name');
  
    const customFooter = (data: any) => {
      // Left footer
      doc.setFontSize(10);
      doc.text(leftFooterContent, 10, doc.internal.pageSize.height - 10);
      // Right footer
      doc.text(
        rightFooterContent,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10
      );
    };
  
    // Create an HTML structure for the card content
    // const cardContent = document.querySelector('#card');
  
    const cardData = document.getElementById(table_card_1);
    const tableData = [document.getElementById(table_id)];
  
    const cardTableParams = {
      theme: 'plain',
      startY: 38, // Adjust the Y coordinate to make space for the header
      body: cardData,
      styles: {
        fontSize: 8,
        halign: 'left', // Adjust text alignment as needed
        fontStyle: 'bold',
        cellWidth: 60, // Set a fixed column width
        cellSpace: 3,
      },
    };
  
    // to set table heading
    doc.setFontSize(16);
    doc.setTextColor(0);
    // (doc as any).setFont('bold');
    const captionText = table_heading;
    const textWidth =
      (doc.getStringUnitWidth(captionText) *
        (doc as any).internal.getFontSize()) /
      doc.internal.scaleFactor;
    const xOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(captionText, xOffset, 30);
  
    const tableParams = {
      // theme: 'plain',
      // startY: 60, // Adjust the Y coordinate to make space for the header
      addPageContent: customFooter,
      // addPageContent1: customHeader,
      fontStyle: 'bold',
      body: tableData,
      styles: {
        fontSize: font_size, // Set the font size for the table content
        halign: 'left', // Right-aligned body text
        fontStyle: 'bold',
        cellWidth: 'warp', // Set a fixed column width
      },
  
      headStyles: {
        // Customize styles for the table headings
        fillColor: [108, 11, 11], // Background color as RGB (red)
        textColor: [255, 255, 255], // Text color as RGB (white)
        fontSize: font_size,
        halign: 'center',
        cellWidth: 'warp', // Set a fixed column width
        fontStyle: 'bold',
      },
      tableWidth: 190, //'190' 'auto' or 'wrap' to adjust table width automatically or 'wrap' to fit content
      margin: { top: 20, left: 10 },
      // Your table data here
      didParseCell: (data: any) => {
      console.log(data)
      if (data.section === 'body') {
  
      const cellText = data.cell.text?.toString().trim(); 
  
      console.log('Cell Value:', cellText);
      if (cellText.toLowerCase().includes("total")) {
        for (const key in data.row.cells) {
          if (data.row.cells.hasOwnProperty(key)) {
            const cell = data.row.cells[key];
            cell.styles.fontStyle = 'bold';
            cell.styles.textColor = 0;
          }
        }
      }
        
        // ✅ If cell is a valid number, align it right
        else if (/^\d+(\.\d+)?\s*\w*$/.test(cellText.replace(/,/g, '').trim())) {  
          data.cell.text = [cellText];  // Keep original text format
          data.cell.styles.halign = 'right';  // Align right for numbers
      } 
           
  
        if (data.section == 'foot') {
          data.cell.styles.halign = 'right';
        }
    }
    },
    };
  
    // Add your table content for multiple pages
    // const tableData = [document.getElementById('curr_table')];
  
    // Function to add the table to the PDF
    const addTableToPDF = () => {
      (doc as any).autoTable({ html: '#' + table_id, ...tableParams });
    };
    const addCardToPDF = (cardTable: HTMLElement | null, params: any) => {
      if (cardTable) {
        (doc as any).autoTable({ html: cardTable, ...params });
      }
    };
    addCardToPDF(cardData, cardTableParams);
  
    // Loop to add the table content
    for (let i = 0; i < tableData.length; i++) {
      addTableToPDF();
  
      if (i === tableData.length - 1) {
        // Define your middle footer content
        const middleFooterContent =
          'Printed on ' +
          (CurentDate.split('-')[2] +
            '-' +
            CurentDate.split('-')[1] +
            '-' +
            CurentDate.split('-')[0]) +
          ' at ' +
          CurrentTime +
          ' by ' +
          user_mobile +
          '';
        const middleFooterContent1 =
          '                            * End of Report *';
        doc.setFontSize(9);
        doc.text(middleFooterContent, 65, (doc as any).autoTableEndPosY() + 10);
        doc.text(
          middleFooterContent1,
          65,
          (doc as any).autoTableEndPosY() + 17
        );
      }
    }
  
    // Adding Page Numbers
    const pageCount = (doc as any).internal.getNumberOfPages();
  
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        '' + i,
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 10,
        { align: 'center' }
      );
    }
  
    // Save or display the PDF
    doc.save(table_name + '.pdf');
  }
}


