import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ServiceService } from 'src/app/mastermodule/service/service.service';
import { Storage } from '@ionic/storage';
import { NavbarComponent } from 'src/app/navbar/navbar.component';

// import { MastermodulePageModule } from './mastermodule/mastermodule.module';
// import { AdmissionPageModule } from './admission/admission.module';
import { ReqInterceptor } from './req-interceptor';

@NgModule({
  // declarations: [AppComponent, NavbarComponent],
  declarations: [AppComponent],
  // entryComponents: [],
  imports: [
    BrowserModule,
    // BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // MastermodulePageModule,
    // AdmissionPageModule,
  ],
  providers: [
    Storage,
    ServiceService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ReqInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
